import BaseModel from './BaseModel';

/** Class representing a tag. */
class Ticket extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Ticket.getListingFields = () => [
    '_id', 'contact.firstname', 'contact.lastname',
    'contact.email', 'status', 'type',
    'activated_pricing.name', 'vdr_auth_serial',
    'vdr_auth_key', 'bookings._id'
];

export default Ticket;
