import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Form, FormGroup, Card, CardTitle, CardBody, Row, Col, Button } from 'reactstrap';
import { reduxForm, Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import {
    ToggleField, EmailLayoutsField, TicketStatusField,
    ScreeningsField, TickettypesField
} from '../../../../components';
import { Utils } from '../../../../services';
import moment from 'moment';

let TicketsFilters = ({ emails, screenings, tickettypes, isLoading, handleSubmit }) => {
    const { t, i18n } = useTranslation();
    const [showFilters, setShowFilters] = useState(true)

    return (
        <Form className="mb-2" onSubmit={handleSubmit}>
            <Card>
                <CardBody>
                    <CardTitle>
                        <i className="fa fa-filter"></i>{t('statstypes.form.filters')}
                        <Button className="btn btn-outline-success float-right" onClick={() => setShowFilters(!showFilters)}><i className={`fa fa-${showFilters ? "minus" : "plus"}`}></i></Button>
                    </CardTitle>
                    {showFilters &&
                    <>
                        <Row>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.search")}</label><br />
                                    <Field
                                        component="input"
                                        type="text"
                                        name="search"
                                        placeholder={t('tickets.filters.search_placeholder')}
                                        className="form-control"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.status")}</label><br />
                                    <TicketStatusField placeholder={t('tickets.status.all')} name="status" />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.tickettypes")}</label><br />
                                    <TickettypesField name="types" tickettypes={tickettypes} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="grey"/>
                        <b className="text-white text-lg">{t('tickets.filters.filter_on_bookings')}</b>
                        <Row>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.having_not_booked")}</label><br />
                                    <ToggleField name="having_not_booked" />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.having_booked")}</label><br />
                                    <ToggleField name="having_booked" />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                                <FormGroup>
                                    <label>{t("tickets.filters.having_booked_screening_id")}</label><br />
                                    <ScreeningsField name="having_booked_screening_id" screenings={screenings} placeholder={t('tickets.filters.having_booked_screening_id_placeholder')} single />
                                </FormGroup>
                            </Col>
                        </Row>
                        {emails?.length > 0 && (
                        <>
                            <hr className="grey"/>
                            <b className="text-white text-lg">{t('tickets.filters.filter_on_emails')}</b>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormGroup>
                                        <label>{t("tickets.filters.received_email")}</label><br />
                                        <EmailLayoutsField emailLayouts={emails} name="received_email" single />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormGroup>
                                        <label>{t("tickets.filters.not_received_email")}</label><br />
                                        <EmailLayoutsField emailLayouts={emails} name="not_received_email" single />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                        )}
                        <Row>
                            <Col>
                                <Button type="submit" color="success" className="float-right" disabled={isLoading}>
                                    {isLoading ?
                                        <span>
                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                        </span>
                                        :
                                        <span>
                                            {t('statstypes.form.show')}&nbsp;
                                            <i className="fa fa-arrow-circle-right"></i>
                                        </span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </>
                    }
                </CardBody>
            </Card>
        </Form>
    )
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
TicketsFilters = reduxForm({
    form: "TicketsFilters",
    enableReinitialize: true
})(TicketsFilters)

// You have to connect() to any reducers that you wish to connect to yourself
TicketsFilters = connect(
    state => ({
        initialValues: state.tickets.filters,
        filters: state.tickets.filters,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(TicketsFilters)

export default TicketsFilters
